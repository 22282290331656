<template>
  <div>
    <div style="margin-top: 30px">
      <label style="display: block; margin-bottom: 10px">
        {{ $t("applicants.max.event.title") }}
        <help>{{ $t("applicants.max.info") }}</help>
      </label>
      <div class="flix-flex flix-flex-inline flix-flex-center flix-btn-group">
        <input
          class="flix-form-control flix-range-control"
          :value="maxApplicants[index]"
          @change.prevent="function (ret) { setAppointments(index, ret) }"
          type="number"
          step="1"
          max="5000"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    event: Object,
    index: Number
  },
  data() {
    return {
      maxApplicants: JSON.parse(JSON.stringify(this.getEvents()))
        .max_applicants,
      maxValue: 5000
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getEvents() {
      return this.$store.state.business.unsaved;
    },
    getBeautifyDate(event) {
      var d1 = new Date();
      d1.setYear(event.begin.split(" ")[0].split("-")[0]);
      d1.setMonth(event.begin.split(" ")[0].split("-")[1] * 1 - 1);
      d1.setDate(event.begin.split(" ")[0].split("-")[2]);
      d1.setHours(event.begin.split(" ")[1].split(":")[0]);
      d1.setMinutes(event.begin.split(" ")[1].split(":")[1]);
      d1.setSeconds(0);
      d1.setMilliseconds(0);

      var d2 = new Date();
      d2.setYear(event.end.split(" ")[0].split("-")[0]);
      d2.setMonth(event.end.split(" ")[0].split("-")[1] * 1 - 1);
      d2.setDate(event.end.split(" ")[0].split("-")[2]);
      d2.setHours(event.end.split(" ")[1].split(":")[0]);
      d2.setMinutes(event.end.split(" ")[1].split(":")[1]);
      d2.setSeconds(0);
      d2.setMilliseconds(0);

      if (d1.getTime() === d2.getTime()) {
        return (
          this.getNullsBefore(d1.getDate()) +
          "." +
          this.getNullsBefore(d1.getMonth() + 1) +
          "." +
          d1.getFullYear() +
          ", " +
          this.getNullsBefore(d1.getHours()) +
          ":" +
          this.getNullsBefore(d1.getMinutes()) +
          " Uhr"
        );
      }
      if (
        d1.getFullYear() != d2.getFullYear() ||
        d1.getMonth() != d2.getMonth() ||
        d1.getDate() != d2.getDate()
      ) {
        return (
          this.getNullsBefore(d1.getDate()) +
          "." +
          this.getNullsBefore(d1.getMonth() + 1) +
          "." +
          d1.getFullYear() +
          ", " +
          this.getNullsBefore(d1.getHours()) +
          ":" +
          this.getNullsBefore(d1.getMinutes()) +
          " Uhr - " +
          this.getNullsBefore(d2.getDate()) +
          "." +
          this.getNullsBefore(d2.getMonth() + 1) +
          "." +
          d2.getFullYear() +
          ", " +
          this.getNullsBefore(d2.getHours()) +
          ":" +
          this.getNullsBefore(d2.getMinutes()) +
          " Uhr"
        );
      }

      return (
        this.getNullsBefore(d1.getDate()) +
        "." +
        this.getNullsBefore(d1.getMonth() + 1) +
        "." +
        d1.getFullYear() +
        ", " +
        this.getNullsBefore(d1.getHours()) +
        ":" +
        this.getNullsBefore(d1.getMinutes()) +
        " - " +
        this.getNullsBefore(d2.getHours()) +
        ":" +
        this.getNullsBefore(d2.getMinutes()) +
        " Uhr"
      );
    },
    getNullsBefore(index) {
      if (index * 1 < 10) {
        return "0" + index;
      } else {
        return index + "";
      }
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.getEvents()));
      data.max_applicants = this.maxApplicants;
      this.$store.commit("business/prefetch", data);
    },
    changeAppointment(index, add) {
      this.maxApplicants = JSON.parse(
        JSON.stringify(this.getEvents())
      ).max_applicants;
      this.maxApplicants[index] = this.maxApplicants[index] * 1 + add;
      this.checkmaxApplicants(index);
    },
    setAppointments(index, nr) {
      this.maxApplicants = JSON.parse(
        JSON.stringify(this.getEvents())
      ).max_applicants;
      this.maxApplicants[index] = nr.target.value;
      this.checkmaxApplicants(index);
    },
    checkmaxApplicants(index) {
      if (this.maxApplicants[index] <= 0) {
        this.maxApplicants[index] = 1;
      }
      if (this.maxApplicants[index] > this.maxValue) {
        this.maxApplicants[index] = this.maxValue;
      }
      this.setSave();
    }
  }
};
</script>
<style lang="sass" scoped></style>
